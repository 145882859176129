import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  { path: '/', name: 'home', component: HomeView },
  { path: '/:catchAll(.*)', redirect: '/', hidden: true }
]

const router = new VueRouter({
  mode: 'hash', // 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
