import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import {
  Input,
  Button,
  Dialog,
  Loading,
  Message,
  Checkbox
} from 'element-ui'
import './styles/global.scss'

Vue.config.productionTip = false

Vue.prototype.$ELEMENT = { size: 'small', zIndex: 3000 }

Vue.use(Input)
Vue.use(Button)
Vue.use(Dialog)
Vue.use(Checkbox)
Vue.use(Loading.directive)

Vue.prototype.$message = Message
Vue.prototype.$loading = Loading.service

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
