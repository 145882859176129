import axios from 'axios'

/**
 * 1.获取 - 图形验证码（用于获取手机验证码）
 * @param {object} data
 * @param {string} data.phone 手机号
 */
export const get_captcha_api = (data) => {
  return axios.post('/cgi-api/web/get_captcha', data)
}

/**
 * 2.获取 - 获取手机验证码
 * @param {object} data
 * @param {string} data.phone 手机号
 * @param {string} data.captcha_id 图形验证码 id
 * @param {string} data.captcha_code 图形验证码 code
 */
export const get_phone_code_api = (data) => {
  return axios.post('/cgi-api/web/get_phone_code', data)
}

/**
 * 3.注册 - 云盒账号
 * @param {object} data
 * @param {string} data.phone 手机号
 * @param {string} data.phone_code 手机验证码
 */
export const box_register_api = (data) => {
  return axios.post('/cgi-api/web/box_register', data)
}
