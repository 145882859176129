<template>
  <div class="container">
    <div class="container-wrapper">
      <div class="register-box">
        <div class="register-box__left"></div>
        <div v-loading="loading" class="register-box__right">
          <div class="title">账号注册</div>
          <!-- 分割线 -->
          <div v-if="box_register_success" class="main-registered">
            <div class="label1">温馨提示：</div>
            <div class="label2">请保存一下云盒账号信息，以防丢失！</div>
            <div class="box-info">
              <span>云盒账号：</span>
              <span class="value">{{ form.phone }}</span>
            </div>
            <div class="box-info">
              <span>初始密码：</span>
              <span class="value">{{ form.phone }}</span>
            </div>
            <div class="btn-go-login2" @click="clickGoLogin">去登录</div>
          </div>
          <div v-if="!box_register_success" class="main">
            <div class="form">
              <div class="form-item-phone">
                <div class="form-item-phone__icon i-phone"></div>
                <div class="form-item-phone__line"></div>
                <el-input ref="FormItemFirst" v-model="form.phone" type="text" maxlength="11" class="input" placeholder="请输入手机号" tabindex="1" />
              </div>
              <div class="form-item-imgcode">
                <div class="form-item-imgcode__icon i-picCode"></div>
                <div class="form-item-imgcode__line"></div>
                <el-input v-model="form.captcha_code" type="text" class="input" placeholder="请输入图形验证码" tabindex="2" @keyup.enter.native="clickRegister" />
                <div class="form-item-imgcode__item-captcha" @click="clickChangeCaptcha">
                  <img class="form-item-imgcode__item-captcha__img" v-if="captcha_src" :src="captcha_src" alt="">
                </div>
              </div>
              <div class="form-item-code">
                <div class="form-item-code__icon i-code"></div>
                <div class="form-item-code__line"></div>
                <el-input v-model="form.phone_code" type="text" class="input" placeholder="请输入短信验证码" tabindex="2" @keyup.enter.native="clickRegister" />
                <el-button type="primary" size="small" plain :disabled="!can_send_code" @click="clickGetPhoneCode">
                  {{ can_send_code ? '获取验证码' : `短信验证 ${counter}s` }}
                </el-button>
              </div>
            </div>
            <!-- 分割线 -->
            <div class="privacy-protocol">
              <el-checkbox v-model="checked_privacy_protocol" style="margin-right: 6px;"/>
              <span>我已阅读并同意《</span>
              <span class="privacy-protocol__label" @click="clickPrivacy">隐私协议</span>
              <span>》和《</span>
              <span class="privacy-protocol__label" @click="clickProtocol">服务条款</span>
              <span>》。</span>
            </div>
            <div :class="['btn-register', checked_privacy_protocol || 'dis']" @click="clickRegister">免费试用</div>
            <div class="btn-go-login">
              <span @click="clickGoLogin">使用已有账户登录</span>
            </div>
          </div>
        </div>
      </div>
      <!-- 分割线 -->
      <div class="copy-info">
        <!-- <span>© 2022 Cyphy Technology (Xiamen) Co.,Ltd.</span> -->
        <span>赛凡信息科技(厦门)有限公司【北京赛凡】</span>
        <a href="https://beian.miit.gov.cn/" target="_blank">闽ICP备17012438号-3</a>
      </div>
    </div>
    <!-- 弹出层：校验验证码 -->
    <el-dialog
      title="获取短信"
      width="520px"
      :visible.sync="check_captcha_show"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <CheckCaptcha v-if="check_captcha_show" :phone="form.phone" @confirm="confirmCheckCaptcha" />
    </el-dialog>
  </div>
</template>

<script>
import _ from 'lodash'
import { box_register_api, get_captcha_api, get_phone_code_api } from '@/api/index'
import CheckCaptcha from '@/components/CheckCaptcha.vue'

const CODE_TIME = 60
const CODE_TIME_KEY = 'SF_CODE_TIME_KEY'

export default {
  name: 'HomeView',
  components: {
    CheckCaptcha
  },
  data () {
    return {
      loading: false,
      counter: 0,
      counter_timer: null,
      form: {
        phone: '',
        captcha_code: '',
        phone_code: ''
      },
      checked_privacy_protocol: false,
      check_captcha_show: false,
      box_register_success: false,
      captcha_id: '',
      captcha_src: ''
    }
  },
  computed: {
    // 能否发送验证码
    can_send_code () {
      return !(this.counter > 0)
    },
    userphone () {
      return this.form.phone
    }
  },
  created () {
    this.$nextTick(() => {
      this.init()
      this.$refs.FormItemFirst.focus()
    })
  },
  watch: {
    userphone (newVal, oldVal) {
      if (!isNaN(newVal) && newVal.length === 11) {
        this.getImgCode()
      }
    }
  },
  methods: {
    // 初始化
    init () {
      const now_time = parseInt(new Date().valueOf() / 1000)
      const code_time = localStorage.getItem(CODE_TIME_KEY) || 0
      const diff_time = now_time - Number(code_time)
      if (diff_time < CODE_TIME) {
        this.counter = CODE_TIME - diff_time
        this.loopCounter()
      }
    },
    async getImgCode () {
      try {
        const { phone } = this.form
        const res = await get_captcha_api({ phone })
        if (res.data.code === 2000) {
          this.captcha_id = res.data.data.captcha_id
          this.captcha_src = res.data.data.captcha_src
        } else {
          const message = res.data.message
          this.$message.error(message)
        }
      } catch (error) {
        console.error(error)
        this.$message.warning('获取验证码图片接口 - 异常')
      }
    },
    initLoopCounter () {
      this.counter = CODE_TIME
      const now_time = parseInt(new Date().valueOf() / 1000)
      localStorage.setItem(CODE_TIME_KEY, now_time)
      this.loopCounter()
    },
    loopCounter () {
      clearTimeout(this.counter_timer)
      this.counter_timer = setTimeout(() => {
        this.counter--
        if (this.counter >= 1) {
          this.loopCounter()
        }
      }, 1000)
    },
    /**
     * 点击 - 获取验证码
     *  1.判断上次一次的发送时间
     *  2.是否为11位手机号码
     *  3.展示图形验证码 - 验证成功发送短信
     */
    clickGetPhoneCode: _.throttle(function () {
      if (!this.can_send_code) {
        return this.$message.warning('请勿频繁操作')
      }
      const { phone } = this.form
      if (!isNaN(phone) && phone.length === 11) {
        // this.check_captcha_show = true
        this.toCheckCode()
      } else {
        this.$message.warning('请输入正确的手机号码')
      }
    }, 700, { trailing: false }),
    // 回调 - 成功 - 获取验证码
    confirmCheckCaptcha () {
      this.initLoopCounter()
      this.check_captcha_show = false
    },
    // 点击 - 切换 - 图片验证码
    clickChangeCaptcha: _.throttle(function () {
      const { phone } = this.form
      if (!isNaN(phone) && phone.length === 11) {
        this.getImgCode()
      } else {
        this.$message.warning('请输入正确的手机号码')
      }
    }, 700, { trailing: false }),
    // 进行 - 校验 - 图片验证码 - 发送手机验证码
    async toCheckCode () {
      if (this.loading) return
      this.loading = true
      try {
        const { captcha_id } = this
        const { phone, captcha_code } = this.form
        const res = await get_phone_code_api({ phone, captcha_id, captcha_code })
        this.loading = false
        if (res.data.code === 2000) {
          this.initLoopCounter()
        } else {
          const message = res.data.message || '验证码认证 - 失败'
          this.$message.error(message)
          this.init()
        }
      } catch (error) {
        this.loading = false
        this.$message.error('验证码认证 - 异常')
      }
    },
    // 点击 - 注册
    clickRegister: _.throttle(function () {
      if (this.checked_privacy_protocol) {
        if (this.form.phone && this.form.phone_code) {
          this.toRegister()
        }
      } else {
        // this.$message.warning('请先阅读并同意《隐私协议》和《服务条款》。')
      }
    }, 700, { trailing: false }),
    // 执行 - 注册
    async toRegister () {
      this.loading = true
      try {
        const { phone, phone_code } = this.form
        const res = await box_register_api({ phone, phone_code })
        if (res.data.code === 2000) {
          this.$message.success('注册企业云盒 - 成功')
          this.box_register_success = true
        } else {
          const message = res.data.message
          if (message === 'PHONE_IS_ALREADY_REGISTERED') {
            this.$message.warning('该手机号已经在云盒中注册')
          } else {
            this.$message.error(message)
          }
        }
      } catch (error) {
        console.error(error)
        this.$message.warning('注册企业云盒接口 - 异常')
      }
      this.loading = false
    },
    // 点击 - 跳转登录
    clickGoLogin: _.throttle(function () {
      const url = 'http://test.saifanbox.com'
      window.open(url, '_blank')
    }, 700, { trailing: false }),
    // 点击 - 跳转 - 隐私政策
    clickPrivacy: _.throttle(function () {
      const url = 'https://www.saifanbox.com/privacy/'
      window.open(url, '_blank')
    }, 700, { trailing: false }),
    // 点击 - 跳转 - 服务条款
    clickProtocol: _.throttle(function () {
      const url = 'https://www.saifanbox.com/protocol/'
      window.open(url, '_blank')
    }, 700, { trailing: false })
  }
}
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-image: url('@/assets/images/background.png');
  .container-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
}

.register-box {
  width: auto;
  height: 520px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  border-radius: 6px;
  overflow: hidden;
  &__left {
    width: 371px;
    height: 100%;
    padding: 10px 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-image: url('@/assets/images/platform.png');
  }
  &__right {
    max-width: 586px;
    width: calc(100vw - 48px);
    height: 100%;
    background-color: #FFFFFF;
    padding: 24px 44px 10px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    .title {
      width: 100%;
      text-align: center;
      position: relative;
      color: #222222;
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 24px;
      color: #222222;
      line-height: 33px;
    }
    .privacy-protocol {
      cursor: default;
      margin: 0 34px;
      margin-top: 24px;
      margin-bottom: 28px;
      &__label {
        cursor: pointer;
        color: #3a62d7;
        transition: .22s;
        opacity: 1;
        &:hover {
          opacity: .9;
          text-decoration: underline;
        }
      }
    }
    .btn-register {
      cursor: pointer;
      color: #fff;
      font-size: 14px;
      user-select: none;
      margin: 0 34px;
      height: 44px;
      // background: linear-gradient(180deg,#74bbfb,#4086f5);
      background: #0876FB;
      border-radius: 6px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      transition: .3s;
      opacity: 1;
      &:hover {
        opacity: .7;
      }
      &.dis {
        cursor: not-allowed;
        opacity: .7;
        &:hover {
          opacity: .7;
        }
      }
    }
    .btn-go-login {
      color: #0876FB;
      font-size: 14px;
      font-weight: 400;
      user-select: none;
      width: 100%;
      height: 40px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      transition: .3s;
      opacity: 1;
      margin-top: 10px;
      &:hover {
        opacity: .9;
        text-decoration: underline;
      }
      span {
        cursor: pointer;
      }
    }
    .main {
      width: 100%;
      height: 390px;
      display: flex;
      flex-direction: column;
      // justify-content: space-between;
    }
    .main-registered {
      width: 100%;
      height: 340px;
      .label1,
      .label2 {
        color: #F56C6C;
        font-size: 24px;
        margin-bottom: 24px;
      }
      .label2 {
        margin-bottom: 34px;
      }
      .box-info {
        color: #333;
        font-size: 20px;
        margin-bottom: 14px;
        .value {
          color: #000;
          text-decoration: underline;
        }
      }
      .btn-go-login2 {
        margin-top: 36px;
        cursor: pointer;
        color: #fff;
        font-size: 14px;
        user-select: none;
        width: 100%;
        height: 44px;
        background: linear-gradient(180deg,#74bbfb,#4086f5);
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        transition: .3s;
        opacity: 1;
        &:hover {
          opacity: .7;
        }
        &.dis {
          cursor: not-allowed;
          opacity: .7;
          &:hover {
            opacity: .7;
          }
        }
      }
    }
  }
}

.form {
  .form-item-phone {
    // width: 100%;
    margin: 0 34px;
    height: 45px;
    border-bottom: 1px solid #ebebeb;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 20px;
    background-color: #F1F1F1;
    border-radius: 6px;
    &__icon {
      width: 15px;
      height: 15px;
      margin-left: 10px;
    }
    &__line {
      width: 1px;
      height: 29px;
      border-radius: 2px;
      margin-left: 7px;
      background-color: #E1E1E1;
    }
    .el-input {
      width: calc(100% - 30px - 10px);
    }
  }
  .form-item-imgcode {
    margin: 0 34px;
    height: 45px;
    border-bottom: 1px solid #ebebeb;
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 20px;
    background-color: #F1F1F1;
    border-radius: 6px;
    &__icon {
      width: 15px;
      height: 15px;
      margin-left: 10px;
    }
    &__line {
      width: 1px;
      height: 29px;
      border-radius: 2px;
      margin-left: 7px;
      background-color: #E1E1E1;
    }
    &__item-captcha {
      width: 230px;
      height: 100%;
      // margin-right: 15px;
      &__img {
        width: 100%;
        height: 100%;
      }
    }
    .el-input {
      width: calc(100% - 30px - 10px - 110px);
    }
    .el-button {
      position: absolute;
      right: 2px;
      z-index: 60;
    }
  }
  .form-item-code {
    // width: 100%;
    margin: 0 34px;
    height: 45px;
    border-bottom: 1px solid #ebebeb;
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    background-color: #F1F1F1;
    border-radius: 6px;
    &__icon {
      width: 15px;
      height: 15px;
      margin-left: 10px;
    }
    &__line {
      width: 1px;
      height: 29px;
      border-radius: 2px;
      margin-left: 7px;
      background-color: #E1E1E1;
    }
    .el-input {
      width: calc(100% - 30px - 10px - 110px);
    }
    .el-button {
      position: absolute;
      right: 2px;
      z-index: 60;
    }
  }
  ::v-deep .el-input__inner {
    font-size: 14px!important;
    border: none;
    margin: 0!important;
    padding-left: 10px !important;
    height: 40px!important;
    line-height: 36px!important;
    background-color: #F1F1F1 !important;

  }
  ::v-deep .el-button--primary.is-plain {
    background: #F1F1F1 !important;
    border-color: #F1F1F1 !important;
    color: #409EFF !important;
    &:hover {
      color: #409EFF !important;
    }
  }
}

.copy-info {
  color: #A7AAAF;
  font-size: 12px;
  width: 100%;
  text-align: center;
  position: absolute;
  bottom: 30px;
  left: 0;
  right: 0;
  z-index: 60;
  a {
    color: #A7AAAF;
    text-decoration: underline;
    margin-left: 5px;
  }
}

@media screen and (max-width: 900px) {
  .register-box {
    &__left {
      display: none;
    }
    &__right {
      padding: 24px 24px 10px;
      .title {
        font-size: 32px;
      }
    }
  }
  .copy-info {
    span {
      display: block;
    }
    a {
      margin-left: 0;
    }
  }
}

.i-phone {
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAAAXNSR0IArs4c6QAAAXVJREFUSEvtl7FLw0AUxr+XSoutk6PoqJNDXVy1/gkV6iC0KYIOoieCYhvXtKIgRnFQkDYFl4D6H9i6uujgpGPB0clWWmyeaClUSaEJgTjkxrt797t7fO/xHcGjQR5x0ROsanoMbOYZNAVw0N4FqUngBymAncxaumIVawlWTwtRfOIeIInBTwRq2AEzOESgSYBNDGBaWU0//o23BmvFM2ZeBknxXZG6sQPt7M0dl+Km2boionNFyCv9gm+ZORYaDgxtJZM1J+CDUinSeGu9E1FZEfJcn+BChRkz4ZFIeDOR+HACPjSMwfprrU6EO0WkZ31wD3H5qbYvL19cfjn55fSjAVXzG8g/aCC5o+ISE491X4WYqtkN+aJ7ztXOta9djje5+Wz1/iAFJ7bF4ktnzVXw96H5E33BbPHoLzihqgjZcOHFRa+sT9vsSVJgPruevLYvLcCZ2fPK3rabiB4DeI8ZUaeGHiRlFJEq923onaTWboxnf6cv1UiaLqEuklEAAAAASUVORK5CYII=');
}
.i-picCode {
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAAAXNSR0IArs4c6QAABE5JREFUSEvNlntolWUcx7/f53nPPJtn81JNDIJAJhqIJAb9YTTKpm4sC2mW7XLmH1mtztTMbedIRO1suhnzzLUxMI/OZjUGOcxLRQ4KpUiKioq0mxReqqF54Vzf51fv4ICOuXMmeHn/e5739zyf53f/ETfp403i4tYDN7f1PGTTfkkB7mu1ihCawMEGn3cTSbn8nlE1bu7snWLHY58AvJfgWQAXxgsXiCZ5J0ROaYtF9S94v08L3ty9+/ZYJHEANFO1ZrUo15/jBUOMspN2HyCzLVr31/sqvskYPGwqcS0JrF5xZtxgAMEt4X4hSjIGB7fsngbG94mIm9nWI4FVFafGC+7r69PHT17qA7kIYGmgtmowrcaOQDAUfhWACtRWvzJeaEq+MbR9qYJ6wkPreZ+v/HxG4GuFjTwnIhwZ0Y7MmHkcDofd/0R13svPVf6VunDTWwO5yQuXclJrnW1F61eV/Ztad3cfdQ3Ffp1MHZukkby4vqb69GhKXBUsIqqpvacbwAPaslfW16w80t6+f8IFc+ZdKswwBo4qCpAIbXb513rDzqMSF4c2QLhQkfkGMkTwvYLpOS1lZWV2RqZuCfUWxCXhBEQuiL0zp+dUnYhG3Ylz5gMR5FKhTww9EFkBIuaC67EEkk2ALBFij4I6aoyUKsp9sHWJf23FZxmBG7fuWkdj/EL0U+RhF1HEyer0MNjARrausOIW4ybSpkTNI1WtwHQI5KuZ0ycudzR0CpGJJ3tJOeSv9W5OC25vfzvvvEkOgIgD9uuEPghIQ9YUvd0BG4N7ADlNqkmAuYPEXgpbbZHDgGvthtXlnemCc1QfbwztXpBE/EMCXwD4QYBiBfziobXsIpIDKVPDKCWwFwOcRa2Wim0+IlSzv7ZyYwrc0rF9notWZE1N5Y9pNQ6Gwl0AlxH4XSBRAT0EZmjoJYZ20Ajiecx/dM6cbPvIt3+Uidi9iqwTwVNweoHbKvY/U36mdevOhQkRp4hsC/i868YEv/HmO3dFEtEDVOona+Jkrycak7MmOgvG3q+g+42Y2YTcLeQxgjn/QwtAJVCqVNl2kQHrCB4TmLNKca4ILylLFTXUVPw8JrglFC5IigpCJORf4z3sCDvl79jJyGtKSxZElABTnX0xFBB/uyjv1/mqP3f2GkM7H6eRYihME8FvWW5Xx/pnnz4+0ufXbRAYHBy0CgsL7dGq1piVK7hlVyVosgK1VdvSRejV/m/a2jPfiHlyIvIDPl9xLG1wtXb15Mdi5pCTTnTrkmvpTk7lawzt+FiR8xVZ2OCr+jotODUI3PB+fNkE4snK0g9e3iTGY/bGUHgPwUWaOrMJpKlj522wsU8gcwXyKcEremkmcAHyKM55ZlvaWlD/Yvl3aU3t+CcYCrcRqtxpEnLlgJgJF5ThhImLwpd5yF+cUXAN56gImzp3zM2IchUhbVsyITd5Yk119bkblsfpHnzdCsgtC/4PtFDzLmbHcc4AAAAASUVORK5CYII=');
}
.i-code {
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAYAAAByDd+UAAAAAXNSR0IArs4c6QAABHBJREFUSEvNlm1sU2UUx8//ubdvGxuW8WGgxjgjCvhBDSGQaGJijEGDMYh1Yxt1Ayl2bAhzC11H/LDhsOBwr0mJMDfkJRWMQYzB6AeDkhgRTQRRVGbEsBkG2xjrvW3vfY65IzVldKwjanw+n3N+z3k/oP/44VZ4TZ173ZZewF88OFX9KQHD4RO24diZRXE2QwCxoohatzrvK59vQSJTcEZACzQYOzPPZKOKCSss1hiAYRB4vwK11e2Y+0Mm4BuAlnG3+xx6RxwOW3woV0vIJ0jSUgKWMLOLQMdVYWuUUoIh61nyYgAaET6CQkecCn2asN925e6cWGxwsIDHf+I64Jbwnlmkm28xUw5As4hxH5N0EZEE6BgT7VKd2Yc3+TzDloNbw5HpMjH6DEtaxZIeJSJxDU4/MeECWOoOl8P3qm/FQDLk1wG3t++9SzfivxGEDuY/GDirEH8BoR7JEfbeigrP1XS56uiITIvCKDAS8adNwiNgnsPAHSB2kFO5Pegr7UsLtDxkzbjAAj2qansl3xXXysrK9EwLwpLr6upy9mt2F8eNDgYXZgSEwDt1ld5yADwVWFKWmdHU2tMlWXrhUmdP6uH/GhgOf5g1ELtcaXM4Dtb6Cn8d65Z/y0OrWIYNrVOyLCWI6vr13uZ/DGj1Z2pPbevpyY4PcohZ+gXEJwSzpK6q/GIS+Hpb926W/OIt5bChbc9jiinXMIvq4IbSPiuMF/WBBmLayKDPhcwtCm547u/St0J6y0BmFm+091QYpmy1jMPMLoYSfZmZgxB0nBPOwvrqovOp1ZwxMNTRlR9P4LwQ2F9X5V2ZNHItfGaImfwAThHRfGZ8o9jVFwL+4nPpWqexpauHGEV2G99ZW1HWn7bxm9+OzNBGoyfA6M1zzVjq8y2NJgWtAhkytBZmWU7AKbtj2rLatct/TgdrjkRcer92mJnvcWVnLdi42nM5LXAsN9rAIQIWO7LEQzUvrexNNWgZivbrNUqWc19gteeXiYbCjs5DBdHEyEliOj7Tlbc89eM3bIum1u5yU8pdBISCVd4AADmVaTOWv/Y9W9g0A4oQqwJV3t2p+jcAt4f3zYzp8c+IqAA25ak6f+mxqQC37eheHBN8FES9Dqf98dRNYdlJu4C3tr27xDCNDyBwVhCtDFR5v80E+ubOA3N0TX+Pie5XhfrspsqSj8frpQVGIhH7uX691pBmAwhfO1VnSfW6wrM3gza2di8k5hZiXqQKZXNBvjPk8XjiGQEtIatAtL7RJmZaD+BHG5Q1NZUlX47PaSQSUX6/GH84ZpjdzHIugJ33zsqqTAebMKTJX1njbEA/vYlIBplgCkKnTaAz1+7+c9StsTEoZxuxmJ8lryWQIoDX8nNky8126KRHlAW9lDj9JJvcwMwPAmIITCcJzMy0kIlzAHwHBZvzbPOPTnZITQpMetve/X7e1ZErhabk58F4gIkJwPdC0MFpObkH1nmXXcqksDIGpk6cYZOsw4qmK6RNdOdMBJ8yMBMvbibzF3WSiDuJUvRGAAAAAElFTkSuQmCC');
}
</style>
