<template>
  <div v-loading="loading" class="check-captcha">
    <div class="check-captcha__item">
      <div class="check-captcha__item-captcha" @click="clickChangeCaptcha">
        <img v-if="captcha_src" :src="captcha_src" alt="">
      </div>
    </div>
    <div class="check-captcha__item">
      <el-input ref="InputRef" v-model="captcha_code" type="text" class="input" tabindex="-1" placeholder="请输入图片对应的验证码" @keyup.enter.native="clickCheckCode" />
    </div>
    <div class="check-captcha__action">
      <el-button type="primary" tabindex="1" :disabled="!disabled" @click="clickCheckCode">提交</el-button>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import { get_captcha_api, get_phone_code_api } from '@/api/index'

export default {
  props: {
    phone: {
      type: String,
      default: () => ''
    }
  },
  data () {
    return {
      loading: false,
      captcha_id: '',
      captcha_src: '',
      captcha_code: ''
    }
  },
  computed: {
    disabled () {
      return !!this.captcha_code
    }
  },
  created () {
    this.$nextTick(() => {
      this.$refs.InputRef.focus()
      this.init()
    })
  },
  methods: {
    async init () {
      this.loading = true
      try {
        const { phone } = this
        const res = await get_captcha_api({ phone })
        if (res.data.code === 2000) {
          this.captcha_id = res.data.data.captcha_id
          this.captcha_src = res.data.data.captcha_src
        } else {
          const message = res.data.message
          this.$message.error(message)
        }
      } catch (error) {
        console.error(error)
        this.$message.warning('获取验证码图片接口 - 异常')
      }
      this.loading = false
    },
    // 点击 - 切换 - 图片验证码
    clickChangeCaptcha: _.throttle(function () {
      this.init()
    }, 700, { trailing: false }),
    // 点击 - 检验 - 图片验证码
    clickCheckCode: _.throttle(function () {
      if (this.captcha_code) {
        this.toCheckCode()
      }
    }, 700, { trailing: false }),
    // 进行 - 校验 - 图片验证码
    async toCheckCode () {
      if (this.loading) return
      this.loading = true
      try {
        const { phone, captcha_id, captcha_code } = this
        const res = await get_phone_code_api({ phone, captcha_id, captcha_code })
        this.loading = false
        if (res.data.code === 2000) {
          this.$emit('confirm')
        } else {
          const message = res.data.message || '验证码认证 - 失败'
          this.$message.error(message)
          this.init()
        }
      } catch (error) {
        this.loading = false
        this.$message.error('验证码认证 - 异常')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.check-captcha  {
  width: 100%;
  height: 100%;
  &__item {
    margin-bottom: 24px;
    .check-captcha__item-captcha {
      cursor: pointer;
      user-select: none;
      display: block;
      width: 300px;
      height: 80px;
      margin: 0 auto;
      img {
        cursor: pointer;
        user-select: none;
        display: block;
        width: 100%;
        height: 100%;
      }
    }
  }
  &__action {
    text-align: right;
  }
}
</style>
